/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/banner_resources.png';
import backgroundMobile from '../../assets/images/banner_resources_mobile.png';
import backgroundTablet from '../../assets/images/banner_resources_tablet.png';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';
import { H2 } from '../Universal/Title/H';

const Wrapper = styled.div`
  background-image: url(${backgroundMobile});
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0rem 2.5rem;
  border: #fbfafa solid 1px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    background-image: url(${backgroundTablet});
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    background-image: url(${background});
    padding: 0;
  }
`;

const Content = styled.div`
  margin-top: 4.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-top: 8.4rem;
  }
`;

const Depiction = styled.p`
  max-width: 45rem;
  margin-top: 1rem;
  text-align: justify;
  text-justify: inter-ideograph;
`;

function InfographicBanner() {
  return (
    <Wrapper>
      <Content>
        <H2>
          生育大補帖！為你找出最合適的資源
        </H2>
        <Depiction>
          面對少子女化議題，中央與各地政府皆擬定各式資源來相挺，眾多補助方案條件各不同，快使用下方篩選器，依據自身狀況選出最適用的資源！
        </Depiction>
      </Content>
    </Wrapper>
  );
}

export default InfographicBanner;
