/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import styled from 'styled-components';
import useScrollPosition from '@react-hook/window-scroll';
import env from '../../env';
import useGoogleSheet from '../../hooks/useGoogleSheet';
import MainContainer from '../Universal/Container/MainContainer';
import ResourcesTitle from './ResourcesTitle';
import ResourcesSearch from './ResourcesSearch';
import ResourcesListItem from './ResourcesListItem';
import ResourcesFilterSelect from './ResourcesFilterSelect';
import TitleBackgroundSearch from '../../assets/icons/bar02_1.png';
import TitleBackgroundSearchM from '../../assets/icons/bar02_1_mobile.png';
import TitleBackgroundFilter from '../../assets/icons/bar02_2.png';
import TitleBackgroundFilterM from '../../assets/icons/bar02_2_mobile.png';
import Loading from '../Universal/Loading/Loading';
import { BREAK_POINT_TABLET } from '../../assets/layout';
import { useViewport } from '../../hooks/useViewport';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
`;

const WrapperContainer = styled(MainContainer)`
  padding-top: 4rem;
  padding-bottom: 10rem;
`;

const TitleFilter = styled(ResourcesTitle)`
  margin-top: 6rem;
`;

const ItemWrapper = styled.div`
  padding: 0 2.5rem;
`;

const Sum = styled.div`
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

const More = styled.div`
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  color: #c6c7ca;
`;

const FilterWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    flex-direction: row;
  }
`;

const DiscWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2.5rem;
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    padding: 0;
  }
`;

const Disc = styled.p`
  max-width: 45rem;
  margin-top: 1rem;
  text-align: justify;
  text-justify: inter-ideograph;
`;

const PAGE_SIZE = 5;

function Resources() {
  const scrollY = useScrollPosition(60);
  const [, , windowHeight] = useViewport();
  const moreRef = useRef(null);
  const [page, setPage] = useState(1);
  const [configData, setConfigData] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [data, loading] = useGoogleSheet(
    'dataMode',
    env.FH_RESOURCE_SHEET_ID,
    'data'
  );
  const [config, configLoading] = useGoogleSheet(
    'configMode',
    env.FH_RESOURCE_SHEET_ID,
    'filter'
  );

  const [searchText, SetSearchText] = useState('');
  const [selectStatus, SetSelectStatus] = useState(null);
  const [selectCategory, SetSelectCategory] = useState(null);
  const [selectLocation, SetSelectLocation] = useState(null);
  const [selectFamily, SetSelectFamily] = useState(null);
  const [selectTax, SetSelectTax] = useState(null);

  const handleSearchChange = (e) => {
    SetSearchText(e.target.value);
  };

  const handleStatusSelect = (e) => {
    SetSelectStatus(e);
  };
  const handleCategorySelect = (e) => {
    SetSelectCategory(e);
  };
  const handleLocationSelect = (e) => {
    SetSelectLocation(e);
  };
  const handleFamilySelect = (e) => {
    SetSelectFamily(e);
  };
  const handleTaxSelect = (e) => {
    SetSelectTax(e);
  };

  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const handleFilter = (inputData, selectFilter, type) => {
    let t = inputData;
    if (selectFilter) {
      if (selectFilter.length > 0) {
        t = filter(t, (o) => {
          let found = false;
          selectFilter.forEach((e) => {
            if (o[type].includes(e.value)) {
              found = true;
            }
          });
          return found;
        });
      }
    }
    return t;
  };

  useEffect(() => {
    const handleSearch = (inputData) => {
      if (searchText === '') return inputData;
      const type = [
        'title',
        'status',
        'category',
        'location',
        'family',
        'tax',
        'target',
        'content',
        'apply',
        'organizer',
      ];
      const sArr = searchText.split(' ');
      let d = inputData;

      d = filter(d, (o) => {
        let found = false;
        sArr.forEach((s) => {
          type.forEach((t) => {
            if (o[t].includes(s)) {
              found = true;
            }
          });
        });
        return found;
      });
      return d;
    };

    if (data) {
      let t = cloneDeep(data);
      t = handleFilter(t, selectStatus, 'status');
      t = handleFilter(t, selectCategory, 'category');
      t = handleFilter(t, selectLocation, 'location');
      t = handleFilter(t, selectFamily, 'family');
      t = handleFilter(t, selectTax, 'tax');
      t = handleSearch(t);
      setFilterData(t);
    }
  }, [
    scrollY,
    data,
    selectStatus,
    selectCategory,
    selectLocation,
    selectFamily,
    selectTax,
    searchText,
  ]);

  useEffect(() => {
    if (data) {
      setDisplayData(filterData.slice(0, page * PAGE_SIZE));
    }
  }, [data, filterData, page]);

  useEffect(() => {
    if (!loading && !configLoading) {
      if (
        scrollY + windowHeight * 0.6 >= moreRef.current.offsetTop &&
        displayData.length < filterData.length
      ) {
        nextPage();
      }
    }
  }, [
    windowHeight,
    scrollY,
    moreRef,
    nextPage,
    displayData,
    filterData,
    loading,
    configLoading,
  ]);

  const formatOption = (options) =>
    options.map((item) => ({ value: item, label: item }));

  useEffect(() => {
    if (config && !configData) {
      setConfigData({
        category: formatOption(config.category.split(',')),
        family: formatOption(config.family.split(',')),
        location: formatOption(config.location.split(',')),
        status: formatOption(config.status.split(',')),
        tax: formatOption(config.tax.split(',')),
      });
    }
  }, [config, configData]);

  const renderItems = () => {
    if (displayData.length <= 0) return null;
    return displayData.map((item) => {
      const t = {
        id: item.id,
        title: item.title,
        organizer: item.organizer,
        category: item.category.split(','),
      };
      return <ResourcesListItem key={item.id} data={t} />;
    });
  };

  if (loading || configLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <WrapperContainer>
      <ResourcesTitle
        background={TitleBackgroundSearch}
        backgroundMobile={TitleBackgroundSearchM}
        title="請輸入關鍵字查詢"
      />
      <ResourcesSearch value={searchText} onChange={handleSearchChange} />
      <TitleFilter
        background={TitleBackgroundFilter}
        backgroundMobile={TitleBackgroundFilterM}
        title="請選擇條件查詢"
      />
      <DiscWrapper>
        <Disc>
          篩選器可複選，請選擇所有符合你狀態的條件，以利搜尋最完整資訊：
          <br />
          ・範例一：若戶籍地在台中市，需在「戶籍地／居住地」同時選擇「全國適用」與「台中市」
          <br />
          ・範例二：若家庭狀態爲低收入戶，需在「家庭狀態」同時選擇「無特別限制」與「低收入戶」
          <br />
          ・範例三：若綜所稅稅率在 20%
          以下，需在「綜所稅稅率」同時選擇「無特別限制」與「低於 20%」
        </Disc>
      </DiscWrapper>
      <FilterWrapper>
        <ResourcesFilterSelect
          id="statusFilter"
          placeholder="人生階段"
          options={get(configData, ['status']) || []}
          value={selectStatus}
          onSelect={handleStatusSelect}
        />
        <ResourcesFilterSelect
          id="categoryFilter"
          placeholder="資訊類型"
          options={get(configData, ['category']) || []}
          value={selectCategory}
          onSelect={handleCategorySelect}
        />
        <ResourcesFilterSelect
          id="locationFilter"
          placeholder="戶籍地／居住地"
          options={get(configData, ['location']) || []}
          value={selectLocation}
          onSelect={handleLocationSelect}
        />
        <ResourcesFilterSelect
          id="familyFilter"
          placeholder="家庭狀態"
          options={get(configData, ['family']) || []}
          value={selectFamily}
          onSelect={handleFamilySelect}
        />
        <ResourcesFilterSelect
          id="taxFilter"
          placeholder="綜所稅稅率"
          options={get(configData, ['tax']) || []}
          value={selectTax}
          onSelect={handleTaxSelect}
        />
      </FilterWrapper>
      <Sum>{`搜尋結果，共 ${filterData.length} 筆`}</Sum>
      <ItemWrapper>{renderItems()}</ItemWrapper>
      <More ref={moreRef}>
        {displayData.length < filterData.length ? <Loading /> : '無更多資料'}
      </More>
    </WrapperContainer>
  );
}

export default Resources;
