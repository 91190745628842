/* eslint-disable object-curly-newline */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';
import { BREAK_POINT_TABLET } from '../../assets/layout';

const animatedComponents = makeAnimated();

const Wrapper = styled.div`
  position: relative;
  margin: 1rem 0.5rem;
`;

const SelectWrapper = styled(Select)`
  width: 19.5rem;
  border-radius: 4px;
  /*border: solid 2px #c6c7ca; */
  /*border-color: ${(props) => (props.isfocus === 1 ? '#50d9e3' : '#c6c7ca')};*/
  background-color: #ffffff;
  color: #404a5b;
  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    width: 10.5rem;
  }
`;

function ResourcesFilterSelect(props) {
  const {
    className,
    id,
    placeholder,
    options,
    value,
    onSelect,
  } = props;
  const selectRef = useRef(null);

  return (
    <Wrapper className={className}>
      <SelectWrapper
        ref={selectRef}
        id={id}
        placeholder={placeholder}
        options={options}
        isMulti
        value={value}
        onChange={onSelect}
        components={animatedComponents}
      />
    </Wrapper>
  );
}

ResourcesFilterSelect.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
};

ResourcesFilterSelect.defaultProps = {
  className: '',
  id: '',
  placeholder: '',
  options: [],
  value: [],
  onSelect: () => {},
};

export default ResourcesFilterSelect;
