import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '../../assets/svg/search-solid.svg';
import { BREAK_POINT_MOBILE } from '../../assets/layout';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-top: 0.8rem;
  }
`;

const Search = styled.input`
  padding: 1.1rem 0;
  width: 18rem;

  vertical-align: middle;
  padding-left: 1rem;
  border-radius: 36px;
  letter-spacing: 0.12px;
  border: solid 1px #c6c7ca;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: 20.75rem;
  }


  ::placeholder {
    color: #c6c7ca;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const SearchBtn = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 1.2rem;
  margin-right: 0.8rem;
  color: #c6c7ca;
  background-color: transparent;
  border: 0;

  &:focus {
    outline: none;
  }
`;

const Icon = styled(SearchIcon)`
  width: 1rem;
  height: auto;
`;

function ResourcesSearch(props) {
  const { value, onChange, onSearch } = props;

  return (
    <Wrapper>
      <SearchWrapper>
        <Search
          type="text"
          placeholder="你想找什麼呢？"
          value={value}
          onChange={onChange}
        />
        <SearchBtn onClick={onSearch}>
          <Icon />
        </SearchBtn>
      </SearchWrapper>
    </Wrapper>
  );
}

ResourcesSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

ResourcesSearch.defaultProps = {
  value: '',
  onChange: () => {},
  onSearch: () => {},
};

export default ResourcesSearch;
