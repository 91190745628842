import React from 'react';
import MainLayout from '../layout/MainLayout';
import SEO from '../components/Universal/SEO';
import ResourcesBanner from '../components/Resources/ResourcesBanner';
import Resources from '../components/Resources/Resources';
import Contact from '../components/Contact/Contact';

const DESC = '永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。生育路上，中央與各地政府皆擬定各式資源來相挺，歡迎參考這份大補帖，找到最合適的資源！';

function ResourcesPage() {
  return (
    <MainLayout>
      <SEO title="政府資源 | 明日戶政所——少子女化全解析" description={DESC} />
      <ResourcesBanner />
      <Resources />
      <Contact />
    </MainLayout>
  );
}

export default ResourcesPage;
