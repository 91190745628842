import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  organizer: PropTypes.string,
  category: PropTypes.arrayOf(PropTypes.string),
});

const preset = {
  id: '000000',
  title: '',
  organizer: '',
  category: [],
};

export default {
  shape,
  preset,
};
