/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAK_POINT_MOBILE } from '../../assets/layout';

const Wrapper = styled.div`
  border-radius: 4px;
  background-color: #189bd3;
  color: white;
  text-align: center;
  padding: 0.2rem 0.5rem;
  margin-right: 0.7rem;
  margin-bottom: 0.5rem;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-right: 1.6rem;
  }
`;

function ResourcesListItemTag(props) {
  const { text, className } = props;

  return (
    <Wrapper className={className}>
      {text}
    </Wrapper>
  );
}

ResourcesListItemTag.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

ResourcesListItemTag.defaultProps = {
  text: '',
  className: '',
};

export default ResourcesListItemTag;
