/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResourcesListItemTag from './ResourcesListItemTag';
import ArrowIcon from '../../assets/svg/arrow-right-solid.svg';
import { H4 } from '../Universal/Title/H';
import ResourcesListItemType from '../../propTypes/ResourcesListItemType';
import { BREAK_POINT_MOBILE } from '../../assets/layout';

const Title = styled(H4)`
  margin-bottom: 1.75rem;
`;

const UnitText = styled(H4)`
  font-weight: bold;
  margin-bottom: 3rem;
`;

const Wrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 #d8d8d8;
  border: solid 1px #c6c7ca;
  background-color: #ffffff;
  padding: 1rem;
  padding-bottom: 1.2rem;
  position: relative;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    padding: 3rem;
    padding-bottom: 1.2rem;
    margin-bottom: 3.6rem;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  width: 12rem;
  flex-wrap: wrap;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: calc(100% - 40px);
  }
`;

const More = styled.a`
  border: 0;
  width: 5rem;
  height: 5rem;
  background-color: ${(props) => (props.hover === 1 ? '#96f1f8' : '#e0e4e7')};
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 1rem 0 0 0;
  color: ${(props) => (props.hover === 1 ? '#404a5b' : '#a1b1bc')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: 6rem;
    height: 6rem;
  }
`;

const Icon = styled(ArrowIcon)`
  width: 1.6rem;
  height: auto;
`;

function ResourcesListItem({ data }) {
  const { id, title, organizer, category } = data;
  const [moreHover, setMoreHover] = useState(0);

  const renderTag = () => {
    if (!category) return null;
    return category.map((item) => (
      <ResourcesListItemTag key={`${id}-tag-${item}`} text={item} />
    ));
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <UnitText>{`主辦單位：${organizer}`}</UnitText>
      <TagWrapper>{renderTag()}</TagWrapper>
      <More
        href={`/resource/${id}`}
        target="_blank"
        rel="noreferrer"
        hover={moreHover}
        onMouseEnter={() => setMoreHover(1)}
        onMouseLeave={() => setMoreHover(0)}
      >
        <Icon />
      </More>
    </Wrapper>
  );
}

ResourcesListItem.propTypes = {
  data: ResourcesListItemType.shape,
};

ResourcesListItem.defaultProps = {
  data: ResourcesListItemType.preset,
};

export default ResourcesListItem;
