/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3, H4 } from '../Universal/Title/H';
import { useViewport } from '../../hooks/useViewport';

const Title = styled(H3)`
  width: 100%;
  text-align: center;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.background})` || ''};
`;

const TitleMobile = styled(H4)`
  width: 100%;
  height: 5.75rem;
  line-height: 5.75rem;
  text-align: center;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.background})` || ''};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function ResourcesTitle(props) {
  const { background, backgroundMobile, title, className } = props;
  const [viewport] = useViewport();

  if (backgroundMobile === '') {
    return (
      <TitleWrapper className={className}>
        <Title background={background}>{title}</Title>
      </TitleWrapper>
    );
  }

  return (
    <TitleWrapper className={className}>
      {viewport === 'mobile' || viewport === 'tablet' ? (
        <TitleMobile background={backgroundMobile}>{title}</TitleMobile>
      ) : (
        <Title background={background}>{title}</Title>
      )}
    </TitleWrapper>
  );
}

ResourcesTitle.propTypes = {
  background: PropTypes.string,
  backgroundMobile: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

ResourcesTitle.defaultProps = {
  background: '',
  backgroundMobile: '',
  title: '',
  className: '',
};

export default ResourcesTitle;
